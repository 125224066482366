<template>
  <div
    class="flex flex-row border-t-2 border-b-2 border-solid h-16 w-1/2 mx-auto hidden md:block md:w-2/3"
  >
    <div class="m-auto font-oxygen text-gray-400 mt-5">
      <font-awesome-icon icon="home" /><font-awesome-icon
        icon="chevron-right"
        class="mx-4"
      />
      <span class="text-gray-600 capitalize font-bold">{{ breadcrumbs }}</span>
      <font-awesome-icon icon="chevron-right" class="mx-4" />
      <span class="text-gray-600 capitalize font-bold">{{
        $route.params.produccion
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["breadcrumbs"]
};
</script>
